<template>
  <div class="recharge">
    <Navbar :title="$t('newAdd.Recharge')">
      <template #right>
        <button @click="rightClick">
          {{ $t("newAdd.Record") }}
        </button>
      </template>
    </Navbar>
    <van-cell-group class="info_bar">
      <van-cell class="cell_item" :title="$t('newAdd.MemberAccount')" :value="account">
      </van-cell>
      <van-cell class="cell_item" :title="$t('My.MemberInformation.balance')"
        :value="`${$money(userInfo.money, false)}`">
        <!-- ≈ USDT${0} -->
      </van-cell>
    </van-cell-group>
    <div class="recharge_number_bar">
      <div class="title">{{ $t("newAdd.RechargeAmount") }}</div>
      <van-field center v-model="number" type="number" :placeholder="$t('newAdd.RechargeAmountPlaceholder')" />
    </div>
    <div class="grid">
      <div class="grid_item" v-for="value in countList" :key="value" @click="addClass(value)"
        :class="{ active: number === value }">
        {{ value }}
      </div>
    </div>
    <div class="recharge_type">
      <div v-if="tabPages.length > 0">
        <div class="tabs" v-for="value, index in tabPages" :key="index">
          <div class="mode" @click="paySubmit(value)">
            <img v-if="value.new_type !== 'idpay'" class="step-image"
              :src="require(`@/assets/images/user_pay/${value.new_type}.png`)" />
            <div class="step">
              <div class="title_bar">
                <div class="title">{{ value.title }}</div>
                <div class="value">
                  {{ $t("recharge.enter") }}
                  <van-icon name="arrow" />
                </div>
              </div>
              <div>
                <div class="item" v-for="(item, index) in (value.desc || '').split('\n')" :key="`item_${index}`">
                  {{ item }}
                </div>
              </div>
            </div>
            <img v-if="value.letter == 1" class="tabbar-letter" :src="require('@/assets/images/user_pay/letter.png')" />
          </div>
        </div>
      </div>
      <div v-else class="tips">
        {{ $t("newAdd.HaveNoteMethod") }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_CONFIG_INFO,
  GET_RECHARGE_LIST,
  getUserInfoNew,
  RECHARGE_COMMIT,
} from "@/api";
import Cookies from "js-cookie";
import { getUserInfo } from "@/utils/tools";
import Tabbar from "@/components/Tabbar";
import verConfig from "@/utils/verConfig";
import {
  NavBar,
  Cell,
  icon,
  field,
  Dialog,
  Toast,
  Grid,
  GridItem,
  tabs,
  tab,
  CellGroup,
} from "vant";
import Navbar from "@/components/JNav";

export default {
  components: {
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [icon.name]: icon,
    [field.name]: field,
    [Toast.name]: Toast,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [tabs.name]: tabs,
    [tab.name]: tab,
    [Dialog.Component.name]: Dialog.Component,
    Tabbar,
    Navbar,
  },
  data() {
    return {
      verConfig,
      countList: ["200", "500", "1000", "3000", "10000", "20000", "50000", "80000"],
      account: "",
      number: "",
      active: 0,
      tabPages: [],
      showTutorial: false,
      count: 1,
      serviceURL: "",
      country: Cookies.get("language"),
      userInfo: {},
      currentCurrency: ""
    };
  },
  created() {
    getUserInfoNew();
    this.getRechargeList();
  },
  mounted() {
    this.$cookie.remove("qrPayData");
    this.account = getUserInfo().userphone;
    this.userInfo = getUserInfo();
  },
  methods: {
    rightClick() {
      this.$router.push({ name: "bankList", params: { type: 'depositRecord' } });
    },
    goback() {
      history.back();
    },
    getRechargeList() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      GET_RECHARGE_LIST().then((r) => {
        Toast.clear();
        if (r.data.ret === 1) {
          Toast.clear();
          const { data } = r.data;
          const res = data.filter(item => item.lists.length)
          let arr = [];
          res.forEach(el => {
            el.lists.forEach(item => {
              item.new_type = el.type;
              item.new_title = el.title
              arr.push(item)
            })
          });
          this.tabPages = arr
        }
      }).catch((e) => {
        Toast.clear();
      });
    },
    addClass(value) {
      this.number = value;
    },
    changeImg() {
      this.count += 1;
      if (this.count === 6) {
        this.showTutorial = false;
        this.count = 1;
      }
    },
    handlerService() {
      window.location.href = this.serviceURL;
    },

    paySubmit(item) {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      RECHARGE_COMMIT({
        id: item.id,
        money: this.number,
        account: this.account,
        // isbefore: 1,
      }).then((r) => {
        Toast.clear();
        if (r.data.ret === 2) {
          Toast.clear();
          window.location.href = r.data.data.url;
        } else if (r.data.ret === 1) {
          if (r.data.data.bank.indexOf("qrcode") != -1) {
            r.data.data.step = item.step;
            r.data.data.id = item.id;
            this.$router.push({
              name: "qrPay",
              params: {
                payData: r.data.data,
              },
            });
          }
        } else if (r.data.ret === 3) {
          if (r.data.data.bank.indexOf("bank-BANK") != -1) {
            r.data.data.step = item.step;
            r.data.data.id = item.id;
            this.$router.push({
              name: "bankPay",
              params: {
                payData: r.data.data,
              },
            });
          }
        } else {
          Toast(r.data.msg);
        }
      }).catch((e) => {
        Toast.clear();
        console.error(e);
      });
    },
  },
  computed: {
    addCount() {
      return this.count;
    },
  },
};
</script>

<style lang="less" scoped>
@Color: #d4a85e;
@wrapPadding: calc(12rem / 16);
@comRadius: 10px;
// @comBG: #07304a;
@comBG: #132235;

.recharge {
  position: relative;
  font-family: "PingFang-Regular";
  // height: 100%;
  min-height: 100vh;
  background: var(--bg);

  .van-nav-bar {
    button {
      background: linear-gradient(179deg, #13a2ba, #087c95);
      border: none;
      color: var(--light);
      font-size: calc(14rem / 16);
      padding: 6px 12px;
      border-radius: 4px;
    }

    .iconfont {
      font-size: calc(22rem / 16);
      color: @Color;
    }
  }

  .info_bar {
    margin: @wrapPadding;
    border-radius: @comRadius;
    background: @comBG;

    /deep/ .van-cell__title {
      color: var(--light);
    }

    /deep/ .van-cell__value {
      color: @Color;
    }

    &::after {
      border: none;
    }

    .cell_item {
      &::after {
        border-color: #124b55;
      }
    }
  }

  .recharge_number_bar {
    margin: @comRadius;
    margin-top: calc(26rem / 16);

    .title {
      text-indent: 0.6em;
      color: var(--light);
      font-size: calc(15rem / 16);
    }

    /deep/ .van-field {
      background: @comBG;
      border-radius: @comRadius;
      margin-top: calc(6rem / 16);

      input::placeholder {
        color: var(--colors);
        opacity: 0.6;
      }

      input {
        color: var(--light);
      }
    }
  }

  .grid {
    margin: @wrapPadding;
    @gap: 6px;
    display: flex;
    flex-wrap: wrap;

    .grid_item {
      width: calc((100% - @gap * 3) / 4 - 2px);
      text-align: center;
      color: #13a2ba;
      border: 1px solid #13a2ba;
      margin-left: @gap;
      margin-bottom: @wrapPadding;
      border-radius: 6px;
      line-height: calc(40rem / 16);

      &:nth-child(1) {
        margin-left: 0;
      }

      &:nth-child(4n + 1) {
        margin-left: 0;
      }
    }

    .active {
      color: var(--light);
      background: linear-gradient(179deg, #13a2ba, #087c95);
    }
  }

  .recharge_type {
    .recharge_number_bar();

    /deep/ .van-tabs__wrap {
      @height: calc(50rem / 16);
      height: @height;
      margin-top: 6px;
      display: none;

      .van-tabs__nav,
      .van-tabs__nav--card {
        background: var(--bg);
        margin: 0;
        border: none;
        height: @height;

        .van-tab {
          margin-left: @wrapPadding;
          width: calc((100% - @wrapPadding) / 2);
          background: #2a68e8;
          color: var(--textColor);
          border: none;
          border-radius: @comRadius;

          &:nth-child(1) {
            margin-left: 0;
          }
        }

        .van-tab--active {
          background: #0094ac;
          border: none;
          color: var(--textColor);
        }
      }
    }
  }

  .van-cell {
    background-color: transparent;
  }

  .list-title-text {
    font-size: calc(13rem / 16);
    color: --gray2;
  }

  .list-title-rtext {
    color: #a87cf3;
    font-size: calc(13rem / 16);
    text-decoration: underline;
  }

  .tabs {
    .mode {
      position: relative;
      display: flex;
      box-sizing: border-box;
      width: 100%;
      margin-top: calc(10rem / 16);
      padding: calc(7.5rem / 16);
      // background-color: #fff;
      background: @comBG;
      border-radius: @comRadius;
    }

    .step {
      font-size: calc(12rem / 16);
      color: var(--light);
      flex-grow: 1;

      .title_bar {
        margin: 0 0 calc(7.5rem / 16) 0;
        display: flex;
        justify-content: space-between;

        .title {
          text-indent: 0;
          color: #fff7b7;
        }

        .value {
          color: var(--colors);
          opacity: 0.5;
        }
      }
    }

    .tabbar-letter {
      position: absolute;
      top: 0;
      right: 0;
      width: calc(32.5rem / 16);
      height: calc(32.5rem / 16);
    }

    .step-image {
      width: calc(50rem / 16);
      height: calc(50rem / 16);
      margin-right: calc(10rem / 16);
    }

  }

  .tips {
    text-align: center;
    margin-top: calc(45rem / 16);
    font-size: calc(10rem / 16);
    color: var(--light);
  }

  .tutorial {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    height: 100%;

    img {
      height: 100%;
      width: 100%;
    }
  }
}

.backimg {
  width: 20px;
  height: 20px;
}
</style>
